import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const AbsolutelyCentered = ({ children, className, axis, ...rest }) => {
  const [width, setWidth] = React.useState(null);
  const [height, setHeight] = React.useState(null);
  const ref = React.useCallback(node => {
    if (node !== null) {
      const { height, width } = node.getBoundingClientRect();
      setWidth(width);
      setHeight(height);
    }
  }, []);

  const centeredStyle = () => {
    switch (axis) {
      case 'vertical':
        return {
          marginTop: -height / 2,
        };
      case 'horizontal':
        return {
          marginLeft: -width / 2,
        };
      case 'both':
        return {
          marginTop: -height / 2,
          marginLeft: -width / 2,
        };
      default:
        return {
          marginTop: -height / 2,
        };
    }
  };

  return (
    <div
      className={cls(
        'absolute',
        {
          'left-1/2': axis === 'horizontal',
          'top-1/2': axis === 'vertical',
          'left-1/2 top-1/2': axis === 'both',
        },
        className,
      )}
      style={centeredStyle()}
      {...rest}
    >
      {children({ ref })}
    </div>
  );
};

AbsolutelyCentered.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  axis: PropTypes.oneOf(['vertical', 'horizontal', 'both']),
};

AbsolutelyCentered.defaultProps = {
  axis: 'horizontal',
};

export default AbsolutelyCentered;
