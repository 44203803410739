import django from 'images/django.png';
import semantic from 'images/semantic.png';
import aws from 'images/aws.png';
import react from 'images/react.png';
import go from 'images/go.png';
import mysql from 'images/mysql.png';
import grafana from 'images/grafana.png';
import kafka from 'images/kafka.png';
import python from 'images/python.png';
import docker from 'images/docker.png';
import ibmCloud from 'images/ibmcloud.png';
import redux from 'images/redux.png';
import graphql from 'images/graphql.png';
import nodejs from 'images/nodejs.png';
import laravel from 'images/laravel.png';
import storybook from 'images/storybook.png';
import immutablejs from 'images/immutablejs.png';
import mongodb from 'images/mongodb.png';
import socketIo from 'images/socket-io.png';
import tailwindcss from 'images/tailwindcss.png';
import mobx from 'images/mobx.png';
import d3 from 'images/d3.png';
import ibm from 'images/ibm.png';
import grab from 'images/grab.png';
import shopee from 'images/shopee.png';
// import icn from 'images/icn.gif';
// import mnemonic from 'images/mnemonic.gif';
import su from 'images/su.gif';
import fats from 'images/fats.gif';
import gtd from 'images/gtd.gif';
// import appointment from 'images/appointment.gif';
import miniGoogleDocs from 'images/mini-google-docs.gif';
import graphvis from 'images/graphvis.mp4';

const technologyDb = {
  DJANGO: {
    label: 'Django',
    src: django,
    link: 'https://www.djangoproject.com',
  },
  SEMANTIC: {
    label: 'Semantic UI',
    src: semantic,
    link: 'https://semantic-ui.com',
  },
  AWS: {
    label: 'AWS',
    src: aws,
    link: 'https://aws.amazon.com',
  },
  REACT: {
    label: 'React',
    src: react,
    link: 'https://reactjs.org',
  },
  REDUX: {
    label: 'Redux',
    src: redux,
    link: 'https://redux.js.org',
  },
  GRAPHQL: {
    label: 'GraphQL',
    src: graphql,
    link: 'https://graphql.org',
  },
  NODEJS: {
    label: 'Node.js',
    src: nodejs,
    link: 'https://nodejs.org',
  },
  LARAVEL: {
    label: 'Laravel',
    src: laravel,
    link: 'https://laravel.com',
  },
  STORYBOOK: {
    label: 'Storybook',
    src: storybook,
    link: 'https://storybook.js.org',
  },
  IMMUTABLEJS: {
    label: 'ImmutableJS',
    src: immutablejs,
    link: 'https://github.com/immutable-js/immutable-js',
  },
  MONGODB: {
    label: 'MongoDB',
    src: mongodb,
    link: 'https://www.mongodb.com',
  },
  SOCKETIO: {
    label: 'SocketIO',
    src: socketIo,
    link: 'https://socket.io',
  },
  TAILWINDCSS: {
    label: 'Tailwind CSS',
    src: tailwindcss,
    link: 'https://tailwindcss.com/',
  },
  MOBX: {
    label: 'MobX',
    src: mobx,
    link: 'https://mobx.js.org/README.html',
  },
  D3: {
    label: 'D3.js',
    src: d3,
    link: 'https://d3js.org',
  },
  GO: {
    label: 'Go',
    src: go,
    link: 'https://go.dev',
  },
  MYSQL: {
    label: 'MySQL',
    src: mysql,
    link: 'https://www.mysql.com',
  },
  GRAFANA: {
    label: 'Grafana',
    src: grafana,
    link: 'https://grafana.com',
  },
  KAFKA: {
    label: 'Kafka',
    src: kafka,
    link: 'https://kafka.apache.org',
  },
  DOCKER: {
    label: 'Docker',
    src: docker,
    link: 'https://www.docker.com',
  },
  PYTHON: {
    label: 'Python',
    src: python,
    link: 'https://www.python.org',
  },
  IBMCLOUD: {
    label: 'IBM Cloud',
    src: ibmCloud,
    link: 'https://www.ibm.com/cloud',
  },
};

export const projectDb = {
  'mini-google-docs': {
    id: 'mini-google-docs',
    title: 'Mini Google Docs',
    link: 'https://realtime-notetaking.netlify.app',
    description:
      'Developed a MERN (MongoDB, Express, React, Node.js) web application that allows collaborative document editing.',
    long: [
      'Developed a MERN (MongoDB, Express, React, Node.js) web application that allows collaborative document editing',
      'Integrated WebSocket in the backend for real-time communication between the client and the server',
      'Implemented JSON Web Token authentication flow',
      'Incorporated rich text editor using Draft.js',
      'Designed RESTful backend server to persist documents in an online database',
    ],
    technologies: [
      technologyDb.REACT,
      technologyDb.NODEJS,
      technologyDb.MONGODB,
      technologyDb.SOCKETIO,
      technologyDb.MOBX,
      technologyDb.TAILWINDCSS,
    ],
    preview: miniGoogleDocs,
    main: 'bg-gray-300',
    dark: 'bg-indigo-700',
    secondary: 'bg-gray-400',
    btnTheme: 'bg-indigo-700',
  },
  graphvis: {
    id: 'graphvis',
    title: 'Graphvis',
    description:
      'Developed a React / Redux / D3.js web application for users to create and edit graphs interactively',
    long: [
      'Developed a React / Redux / D3.js web application for users to create and edit graphs interactively',
      'Created visualization for various graph algorithms such as Dijkstra’s shortest path, A* search, Bellman-Ford',
      'Implemented interactive features such as zooming, panning, drag-and-drop using JavaScript and CSS',
    ],
    technologies: [
      technologyDb.REACT,
      technologyDb.REDUX,
      technologyDb.TAILWINDCSS,
      technologyDb.D3,
    ],
    link: 'https://graphvis.netlify.app',
    preview: graphvis,
    main: 'bg-gray-300',
    dark: 'bg-indigo-700',
    secondary: 'bg-gray-400',
    btnTheme: 'tw-btn-blue',
    useVideo: true,
  },
  fats: {
    id: 'fats',
    title: 'Face-Recognition Attendance Taking System',
    description:
      "Developed a React/Redux/Django web application for managing student's attendances for registered courses",
    long: [
      "Developed a React/Redux/Django web application for managing student's attendances for registered courses",
      'Implemented real-time face detection using VGG Keras machine learning model',
    ],
    technologies: [technologyDb.REACT, technologyDb.REDUX, technologyDb.DJANGO],
    preview: fats,
    main: 'bg-gray-300',
    dark: 'bg-blue-800',
    btnTheme: 'tw-btn-blue',
    text: 'text-gray-800',
  },
  su: {
    id: 'su',
    title: 'NTU Student Union Portal',
    description:
      'Built a web-based portal for event coordinators to authenticate the event attendees',
    long: [
      'Built a web-based portal for event coordinators to authenticate the event attendees',
      'This project is used for coordinating big campus events such as examination welfare pack distribution which is attended by more than 2000 students every year',
    ],
    technologies: [
      technologyDb.DJANGO,
      technologyDb.SEMANTIC,
      technologyDb.AWS,
    ],
    link: 'https://app.ntusu.org',
    preview: su,
    main: 'bg-blue-800',
    dark: 'bg-indigo-800',
    btnTheme: 'tw-btn-indigo',
    text: 'text-white',
  },
  // icn: {
  //   id: 'icn',
  //   title: 'ICN Website',
  //   description:
  //     'Developed the administration dashboard to manage tickets, promotions, payments',
  //   long: [
  //     'Developed the administration dashboard to manage tickets, promotions, payments',
  //     'The project dashboard is used for managing Indonesian Cultural Night, a musical production that is attended by 800 audiences every year',
  //   ],
  //   technologies: [
  //     technologyDb.REACT,
  //     technologyDb.REDUX,
  //     technologyDb.STORYBOOK,
  //     technologyDb.IMMUTABLEJS,
  //   ],
  //   main: 'bg-yellow-800',
  //   btnTheme: 'tw-btn-brown',
  //   secondary: 'transparent',
  //   dark: 'bg-orange-700',
  //   text: 'text-white',
  //   link: 'https://test.admin.icnmusical.com',
  //   preview: icn,
  // },
  gtd: {
    id: 'gtd',
    title: 'Get Together Day Website',
    description: 'Built a web application for users to play games in GTD event',
    long: [
      'Built a web application for users to play games in GTD event',
      'This project is used for Q&A games with QR code scanner, level-based rally games for Get Together Day (GTD), a freshmen orientation event participated by 150 people every year',
    ],
    technologies: [
      technologyDb.REACT,
      technologyDb.REDUX,
      technologyDb.STORYBOOK,
      technologyDb.IMMUTABLEJS,
    ],
    // link: 'https://gtdxxi.pintugtd.com',
    main: 'bg-red-700',
    dark: 'bg-orange-800',
    text: 'text-white',
    btnTheme: 'tw-btn-brown',
    preview: gtd,
  },
  // appointment: {
  //   id: 'appointment',
  //   title: 'Appointment Web App',
  //   description:
  //     'Built a web application for signing people in and out for an appointment by taking their pictures and identity card using HTML5 Webcam',
  //   long: [
  //     'Built a web application for signing people in and out for an appointment by taking their pictures and identity card using HTML5 Webcam',
  //   ],
  //   technologies: [
  //     technologyDb.REACT,
  //     technologyDb.REDUX,
  //     technologyDb.NODEJS,
  //     technologyDb.GRAPHQL,
  //     technologyDb.AWS,
  //   ],
  //   preview: appointment,
  //   btnTheme: 'tw-btn-teal',
  //   main: 'bg-teal-700',
  //   dark: 'bg-teal-900',
  //   text: 'text-white',
  // },
  // mnemonic: {
  //   id: 'mnemonic',
  //   title: 'Mnemonic',
  //   description:
  //     'Built a paperless RSVP system for events such as birthdays and weddings',
  //   long: [
  //     'Built a paperless RSVP system for events such as birthdays and weddings. Developed the content-management system (CMS) for editing the main website',
  //   ],
  //   technologies: [technologyDb.REACT, technologyDb.LARAVEL],
  //   link: 'http://test.mnemonic.co.id',
  //   preview: mnemonic,
  //   btnTheme: 'tw-btn-brown',
  //   main: 'bg-yellow-mnemonic',
  //   secondary: 'transparent',
  //   dark: 'bg-orange-700',
  //   text: 'text-black',
  // },
};

export const experiencesDb = {
  shopee: {
    logo: shopee,
    id: 'shopee',
    company: 'Shopee',
    role: 'Software Engineer',
    period: '7/2021 - Present',
    background: 'bg-orange-100',
    text: 'text-shopee',
    periodText: 'text-shopee',
    underline: 'after:bg-shopee',
    textDescription: 'text-black',
    long: [
      'Designed and developed backend systems to support Shopee’s business use cases, focusing on scalability and maintainability',
      'Mentored team members on software engineer principles and best practices via sync ups, code and design reviews',
      'Analyzed service’s capacity and execute the necessary scaling exercise for big promotional campaigns',
    ],
    technologies: [
      technologyDb.GO,
      technologyDb.PYTHON,
      technologyDb.MYSQL,
      technologyDb.GRAFANA,
      technologyDb.KAFKA,
      technologyDb.DOCKER,
    ],
    tooltip: 'bg-shopee text-white',
  },
  grab: {
    logo: grab,
    id: 'grab',
    company: 'Grab',
    role: 'Intern - Fullstack Developer',
    period: '5/2020 - 8/2020',
    background: 'bg-white',
    text: 'text-grab',
    periodText: 'text-grab',
    underline: 'after:bg-grab',
    textDescription: 'text-black',
    long: [
      'Developed Node.js / TypeScript AWS Lambda functions to optimize user-uploaded images. Resulted in a 50% daily bandwidth size reduction and 20% AWS monthly cost reduction.',
      'Automated serverless deployments to AWS. Resulted in 50% faster deployment pipeline workflows.',
      'Built Node.js interactive Command Line Interface migration tool. Automated font migration process for 1,000+ old websites',
    ],
    technologies: [
      technologyDb.NODEJS,
      technologyDb.MYSQL,
      technologyDb.AWS,
      technologyDb.DOCKER,
    ],
    tooltip: 'bg-grab text-white',
  },
  ibm: {
    logo: ibm,
    id: 'ibm',
    company: 'IBM',
    role: 'Intern - Software Engineer',
    period: '8/2019 - 12/2019',
    background: 'bg-blue-100',
    text: 'text-black',
    textDescription: 'text-black',
    underline: 'after:bg-black',
    periodText: 'text-black',
    long: [
      'Developed React / Redux / Node.js administration dashboard that allows users to interact with 30+ IBM Cloud Identity Governance APIs. Resulted in an improved workflow for the API team to test and demo the APIs',
      'Developed Docker / Node.js account-level application migration tool. Used by clients, such as Ford, to migrate 30+ applications',
      'Leveraged test-driven development to incorporate unstable APIs, sudden requirement changes with tight deadlines',
    ],
    technologies: [
      technologyDb.REACT,
      technologyDb.REDUX,
      technologyDb.NODEJS,
      technologyDb.IBMCLOUD,
      technologyDb.DOCKER,
    ],
    tooltip: 'bg-blue-600 text-white',
  },
};
